import { AirportLocation } from '@reward-platform/flights-schemas'
import { useQuery } from '@tanstack/react-query'
import { getAirport } from '~/services/locationService'

const useGetAirport = (airportCode?: string): AirportLocation | undefined => {
  const { data } = useQuery({
    queryKey: ['getAirport', airportCode],
    queryFn: async () => {
      if (airportCode) {
        const airport = await getAirport(airportCode)
        if (airport == null) {
          throw new Error(`Airport not found`)
        }
        return airport
      }
      return undefined
    },
    enabled: !!airportCode,
    meta: {
      notifyUser: true,
      errorMessage: 'Location Error - could not get airport',
    },
  })

  return data
}

export default useGetAirport
