import { z } from 'zod'
import { FlightDateSchema, FlightsPartnerIdentifierSchema } from './common'
import { AirportLocationSchema } from './location'

const AirportSchema = AirportLocationSchema.and(
  z.object({ cityCode: z.string(), timeZone: z.string() })
)

export const FlightScheduleSchema = z.object({
  flightNumber: z.string(),
  originAirport: AirportSchema,
  destinationAirport: AirportSchema,
  carrierCode: z.string(),
  applicableFromDate: z.string(),
  applicableToDate: z.string(),
  departureTime: z.string(),
  departureTimeOffset: z.string(),
  arrivalTime: z.string(),
  arrivalTimeOffset: z.string(),
  operatesMonday: z.boolean(),
  operatesTuesday: z.boolean(),
  operatesWednesday: z.boolean(),
  operatesThursday: z.boolean(),
  operatesFriday: z.boolean(),
  operatesSaturday: z.boolean(),
  operatesSunday: z.boolean(),
  partnerId: FlightsPartnerIdentifierSchema,
})

export const FlightScheduleQuerySchema = z.object({
  departureDate: FlightDateSchema,
  originAirportCode: z.string().length(3),
  destinationAirportCode: z.string().length(3),
  partnerId: FlightsPartnerIdentifierSchema,
})

export type FlightScheduleResponse = z.infer<typeof FlightScheduleSchema>
export type FlightScheduleSearchQueryParameters = z.infer<typeof FlightScheduleQuerySchema>
