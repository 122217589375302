// functions mapping one flight type to another that can be shared by bff and fe

import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { CabinClass, cabinCodeToCabinClassMap, FlightLegIdentifiers } from './common'
import {
  CabinName,
  cabinNameToCabinClass,
  FlightResult,
  FlightCabinSelection,
} from './flight-availability'
import {
  UnfilledSearchFormData,
  FlightResultsQueryData,
  FlightSearchFormData,
  FlightSearchQueryData,
} from './flight-form'
import { CabinClassSchema } from './flight-pricing'

const queryDateFormat = 'yyyy-MM-dd'

/**
 * Construct a journeyId,
 * to identify a group of outbound and inbound flights which will be booked together
 * */
export const generateJourneyId = (paramsArray: FlightLegIdentifiers[]): string => {
  return paramsArray
    ?.map(({ departureDate, flightNumber, marketingCarrier, rbdCode }) =>
      `${departureDate.replace(
        /Z.*/, // remove the timezone info
        ''
      )}${marketingCarrier}${flightNumber}${rbdCode}`.toUpperCase()
    )
    .join('_')
}

export const formDataToSearchQueryParams = (
  data: UnfilledSearchFormData
): FlightSearchQueryData => {
  return {
    departureDate: data.dates?.startDateTime
      ? format(data.dates.startDateTime, queryDateFormat)
      : undefined,
    returnDate: data.dates?.endDateTime
      ? format(data.dates.endDateTime, queryDateFormat)
      : undefined,
    fromAirportCode: data.fromLocation?.airportCode ?? undefined,
    toAirportCode: data.toLocation?.airportCode ?? undefined,
    oneWayOnly: data.oneWayOnly?.toString() ?? undefined,
    adult: data.travellers?.adult?.quantity?.toString() ?? '0',
    child: data.travellers?.child?.quantity?.toString() ?? '0',
    childrenAges: data.travellers?.childrenAges?.map((child) => child?.age).join(',') ?? '0',
  }
}

export const formDataToQueryParams = (data: FlightSearchFormData): FlightResultsQueryData => {
  const { dates, travellers } = data

  return {
    departureDate: format(dates.startDateTime, queryDateFormat),
    returnDate: (dates.endDateTime && format(dates.endDateTime, queryDateFormat)) ?? undefined,
    fromAirportCode: data.fromLocation.airportCode,
    toAirportCode: data.toLocation.airportCode,
    oneWayOnly: data.oneWayOnly.toString(),
    adult: travellers.adult.quantity.toString(),
    child: travellers.child.quantity.toString(),
    childrenAges: travellers.childrenAges.map((child) => child.age).join(','),
  }
}

export const availableCabinClasses = (flight: FlightResult): CabinClass[] => {
  const availableCabinNames = Object.keys(flight.cabinClasses) as CabinName[]
  return availableCabinNames.map((cabinClassName) => cabinNameToCabinClass[cabinClassName])
}

export const getCabinInfo = (
  flightResult: FlightResult,
  cabinClass: CabinClass
): Pick<FlightCabinSelection, 'cabinCode' | 'rbd' | 'price'> => {
  const cabinClassResult = Object.values(flightResult.cabinClasses).find(
    (c) => cabinCodeToCabinClassMap[c.cabinCode] === cabinClass
  )

  if (!cabinClassResult) {
    throw new Error(`Couldn't find cabin class ${cabinClass} in flight result`)
  }

  const parsedCabinClass = CabinClassSchema.parse(cabinClass)
  const formattedCabinClass = capitalize(
    parsedCabinClass.toLowerCase()
  ) as keyof FlightResult['cabinClasses']

  const flightResultPrice = flightResult.cabinClasses[formattedCabinClass]?.price

  if (!flightResultPrice) {
    throw new Error(`Couldn't find price for cabin class ${cabinClass} in flight result`)
  }

  return {
    cabinCode: cabinClassResult.cabinCode,
    rbd: cabinClassResult.rbd,
    price: flightResultPrice,
  }
}
