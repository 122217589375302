import { z } from 'zod'
import { FlightsPartnerIdentifierSchema } from './common'
import { singleDigitRegexp } from './flight-availability'

const TotalPriceSchema = z.object({
  totalCashAmount: z.number(),
  loyaltyAmount: z.number(),
})

export type TotalPrice = z.infer<typeof TotalPriceSchema>

const BaggageAllowanceIdSchema = z.enum([
  'BAGGAGE_ALLOWANCE_1',
  'BAGGAGE_ALLOWANCE_2',
  'BAGGAGE_ALLOWANCE_3',
  'BAGGAGE_ALLOWANCE_4',
  'BAGGAGE_ALLOWANCE_5',
])

const BaggageTypeCodeSchema = z.enum(['Checked', 'CarryOn'])
const WeightAllowanceSchema = z.object({
  maximumWeightMeasure: z.number().optional(),
  weightUnitOfMeasurement: z.enum(['KGM', 'LBR']),
})

export const BaggageAllowanceListSchema = z.array(
  z.object({
    baggageAllowanceID: BaggageAllowanceIdSchema,
    pieceAllowance: z.object({ totalQty: z.number() }).optional(),
    typeCode: BaggageTypeCodeSchema,
    weightAllowance: z.array(WeightAllowanceSchema).optional(),
  })
)

export type BaggageAllowanceListType = z.infer<typeof BaggageAllowanceListSchema>

// Offer information needed for the basket
export const FlightAirShopOfferSchema = z.object({
  offerID: z.string(),
  ownerCode: FlightsPartnerIdentifierSchema,
  offerItemID: z.string(),
  paxRefID: z.array(z.string()),
  currencyCode: z.string(),
  totalPrice: TotalPriceSchema,
  baggageAllowances: BaggageAllowanceListSchema,
})

export type FlightAirShopOffer = z.infer<typeof FlightAirShopOfferSchema>

export const FlightPricingOptionsSchema = z.record(TotalPriceSchema)

export type FlightPricingOptions = z.infer<typeof FlightPricingOptionsSchema>

export const FlightPricingOptionsQuerySchema = z.object({
  adult: z.string().regex(singleDigitRegexp).optional(),
  child: z.string().regex(singleDigitRegexp).optional(),
  infant: z.string().regex(singleDigitRegexp).optional(),
  offerId: z.string(),
  youth: z.string().regex(singleDigitRegexp).optional(),
})
export type FlightPricingOptionsParameters = z.infer<typeof FlightPricingOptionsQuerySchema>
