import { ReactElement, useMemo } from 'react'
import { chakra, useMultiStyleConfig } from '@chakra-ui/react'
import SelectBox from '@reward-platform/lift/components/SelectBox'
import useIntl from '~/hooks/useIntl/useIntl'
import { ChildAge } from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoomPicker.types'

const createChildAgesArray = (t: { under1: string; yearsOld: (value: number) => string }) => {
  const ageArray = [
    {
      label: `${t.under1}`,
      value: 0,
    },
    {
      label: `${t.yearsOld(1)}`,
      value: 1,
    },
  ]
  const iterator = [...Array(16)]
  iterator.forEach((_, i) => {
    ageArray.push({ label: `${t.yearsOld(i + 2)}`, value: i + 2 })
  })
  return ageArray
}

interface ChildrenAgePickerProps {
  childrenAges?: ChildAge[]
  errors?: string | string[]
  handleSelect: (params: ChildAge) => void
  placeholder?: string
}

export const ChildrenAgePicker = ({
  childrenAges,
  errors,
  handleSelect,
  placeholder,
}: ChildrenAgePickerProps): ReactElement => {
  const styles = useMultiStyleConfig('ChildrenAgePicker')

  const intl = useIntl()

  const t = {
    agesOfChildren: intl.formatMessage({
      id: 'ages-of-children',
      defaultMessage: 'Ages of children',
    }),
    under1: intl.formatMessage({
      id: 'child-under-1',
      defaultMessage: 'Under 1',
    }),
    yearsOld: (value: number) =>
      intl.formatMessage(
        {
          id: 'child-years-old',
          defaultMessage: `{count} {count, plural, one {year old} other {years old}}`,
        },
        { count: value }
      ),
  }

  const childAgesArray = createChildAgesArray(t)

  const hasChildren = childrenAges && childrenAges.length > 0

  const childrenAgePicker = useMemo(() => {
    return (
      hasChildren &&
      childrenAges.map((c, i) => {
        const key = i + 1

        return (
          <chakra.div __css={styles.ageSelectBoxContainer} key={key} data-testid="child-age-select">
            <SelectBox
              label={`Child ${key}`}
              source={childAgesArray}
              id={`child_${key}`}
              value={c.age}
              handleSelect={(age) => handleSelect({ child: `child_${key}`, age: Number(age) })}
              errors={errors}
              placeholder={placeholder}
            />
          </chakra.div>
        )
      })
    )
  }, [
    placeholder,
    hasChildren,
    childrenAges,
    styles.ageSelectBoxContainer,
    childAgesArray,
    errors,
    handleSelect,
  ])

  return (
    <div data-testid="children-age-picker">
      <chakra.div __css={{ ...styles.childrenAgePickerContainer }}>
        {hasChildren && (
          <>
            <chakra.span __css={styles.agesOfChildrenLabel}>{t.agesOfChildren}</chakra.span>
            {childrenAgePicker}
          </>
        )}
      </chakra.div>
    </div>
  )
}

export default ChildrenAgePicker
