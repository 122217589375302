import React, { ReactElement, useRef, useState } from 'react'
import { useLiftMediaQuery } from '@reward-platform/lift/hooks/useLiftMediaQuery'
import { deviceSizes } from '@reward-platform/lift/theme/deviceSizes'
import MobileModal from '@reward-platform/lift/components/MobileModal'
import { defaultIconProps } from '@reward-platform/lift/components/ChakraListBox'
import TravelerQuantityPicker, {
  TravelersQuantity,
} from '~/components/shared/TravelerQuantityPicker/TravelerQuantityPicker'
import useIntl from '~/hooks/useIntl/useIntl'
import {
  Box,
  Flex,
  Button,
  useMultiStyleConfig,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import { useFormatFlightTravellers } from '~/hooks/useFormatFlightTravellers/useFormatFlightTravellers'
import { ChildrenAgePicker } from '~/components/shared/ChildrenAgePicker'
import { TravellersFormData } from '@reward-platform/flights-schemas'
import { updateAgeOfChild, updateChildrenAges } from '~/components/shared/ChildrenAgePicker/utils'
import { AlertIcon, Alert, AlertDescription, ChakraInput } from '@reward-platform/lift/components'
import useBasket from '~/hooks/useBasket/useBasket'

export interface FlightTravellerInputTheme {
  backgroundColor: string
  borderRadius: string
  badgeBackgroundColor: string
  spacing: string // margins and padding
  pickerBoxShadow: string
}

interface PickerLabelProps {
  travellerType: string
  ageRange?: string
}

const PickerLabel = (props: PickerLabelProps) => {
  const styles = useMultiStyleConfig('FlightTravellerInput', {})
  const { travellerType, ageRange } = props
  return (
    <Box __css={styles.pickerLabelContainer}>
      <Box>{travellerType}</Box>
      {ageRange && <Box __css={styles.travellerAgeRange}>{`(${ageRange})`}</Box>}
    </Box>
  )
}

interface TravellerInputProps {
  onChange(value: TravellersFormData): void
  value: TravellersFormData
  variant: string
}

export const defaultTravellerQuantity = {
  adult: {
    quantity: 1,
  },
  child: {
    quantity: 0,
  },
}

export const MAX_TRAVELLERS = 6

enum Errors {
  InfantsExceedingAdults = 'InfantsExceedingAdults',
  NoAdultPresentForChild = 'NoAdultPresentForChild',
}

const validateTravellerQuantities = (value: TravellersFormData): Errors | null => {
  const infants = value.childrenAges.filter((child) => child.age < 2).length
  const children = value.child.quantity
  const adults = value.adult.quantity

  const totalChildren = children + infants

  if (totalChildren > 0 && adults < 1) {
    return Errors.NoAdultPresentForChild
  }

  if (infants > adults) {
    return Errors.InfantsExceedingAdults
  }

  return null
}

function FlightTravellerInput({ onChange, value, variant }: TravellerInputProps): ReactElement {
  const styles = useMultiStyleConfig('FlightTravellerInput', { variant })
  const wrapper = useRef<HTMLDivElement>(null)
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const isDesktop = useLiftMediaQuery(`(min-width: ${deviceSizes.medium})`)
  const [error, setError] = useState<Errors | null>(null)

  const { basket } = useBasket()
  const hotelInBasket = basket.data?.items.HOTEL && basket.data.items.HOTEL.length > 0

  const placeholder = useFormatFlightTravellers({
    adult: value.adult.quantity,
    child: value.child.quantity,
  })

  const intl = useIntl()

  const tErrors: { [key in Errors]: string } = {
    [Errors.InfantsExceedingAdults]: intl.formatMessage({
      id: 'infants-exceeding-adults',
      defaultMessage: 'There cannot be more Infants than Adults.',
    }),
    [Errors.NoAdultPresentForChild]: intl.formatMessage({
      id: 'no-adult-present-for-child',
      defaultMessage: 'Children must be accompanied by at least one Adult.',
    }),
  }

  const t = {
    maxTravelers: intl.formatMessage(
      { id: 'max-travelers', defaultMessage: `You may add up to {maxTravelers} total travellers` },
      { maxTravelers: MAX_TRAVELLERS }
    ),
    sameTravellers: intl.formatMessage({
      id: 'same-travellers',
      defaultMessage: 'Please ensure that all travellers on flights and hotels are the same.',
    }),
    correctAge: intl.formatMessage({
      id: 'correct-age',
      defaultMessage:
        'Please note that the age entered must match the traveller’s age on the day of the flight.',
    }),
    incorrectPassengerSelection: intl.formatMessage({
      id: 'incorrect-passenger-selection',
      defaultMessage: 'Incorrect passenger selection',
    }),
    done: intl.formatMessage({ id: 'done', defaultMessage: 'Done' }),
  }

  const handleTravellerQuantityChange = (travellerQuantity: TravelersQuantity) => {
    // compare with previous state and call update function if children count changed
    const didChildChange = value.childrenAges.length !== travellerQuantity.child.quantity

    const travellerInput: TravellersFormData = {
      ...travellerQuantity,
      childrenAges: didChildChange
        ? updateChildrenAges(value.childrenAges, travellerQuantity.child.quantity) ?? []
        : value.childrenAges,
    }

    setError(validateTravellerQuantities(travellerInput))

    onChange(travellerInput)
  }

  const Picker = (
    <Flex data-testid="travel-picker-container" __css={styles.pickerContainer}>
      <Box __css={styles.maxTravellerBadge}>{t.maxTravelers}</Box>
      {error && (
        <Box __css={styles.infoMessageWrapper}>
          <Box>{tErrors[error]}</Box>
        </Box>
      )}

      {hotelInBasket && (
        <Alert status="warning" variant="thin">
          <AlertIcon iconName="info" iconSize="sm" />
          <AlertDescription fontSize="xs">{t.sameTravellers}</AlertDescription>
        </Alert>
      )}

      <Box mt={6}>
        <TravelerQuantityPicker
          maxTravelers={MAX_TRAVELLERS}
          onChange={handleTravellerQuantityChange}
          travelers={{
            adult: {
              limits: { minCount: 1, maxCount: MAX_TRAVELLERS },
              quantity: value.adult.quantity,
              label: <PickerLabel travellerType="Adults" />,
              isVisible: true,
            },
            child: {
              limits: {
                minCount: 0,
                maxCount:
                  error === Errors.NoAdultPresentForChild ? value.child.quantity : MAX_TRAVELLERS,
              },
              quantity: value.child.quantity,
              label: <PickerLabel travellerType="Children" ageRange="Aged 0 to 17" />,
              isVisible: true,
            },
          }}
          variant={variant}
        />
      </Box>

      <Box sx={styles.childrenAgePickerContainer}>
        <ChildrenAgePicker
          childrenAges={value.childrenAges}
          handleSelect={({ child, age }) => {
            const childIndex = +child.split('_')[1] // relies on key being in 'child_<Number>' format
            const travellerInput: TravellersFormData = {
              ...value,
              childrenAges: updateAgeOfChild(value.childrenAges, { child, age }, childIndex),
            }

            onChange(travellerInput)
          }}
        />
      </Box>

      {value.childrenAges.length >= 1 && (
        <Alert status="warning" variant="thin" mb={6}>
          <AlertIcon iconName="info" iconSize="sm" />
          <AlertDescription fontSize="xs">{t.correctAge}</AlertDescription>
        </Alert>
      )}

      <Button sx={styles.doneButton} size="sm" onClick={onClose} variant={variant}>
        {t.done}
      </Button>
    </Flex>
  )

  const Input = (
    <ChakraInput
      id="dropdown-traveller-input"
      data-testid="dropdown-traveller-input"
      name="Travellers"
      label="Travellers"
      className={isOpen ? 'fake-focus' : ''}
      isReadOnly
      error={validateTravellerQuantities(value) ? t.incorrectPassengerSelection : undefined}
      placeholder={placeholder}
      value={placeholder}
      onClick={onOpen}
      onKeyDown={(keyEvent) => {
        if (keyEvent.code === 'Enter' || keyEvent.code === 'Space') {
          keyEvent.preventDefault()
          onToggle()
        }
      }}
      {...defaultIconProps}
    />
  )

  return (
    <div ref={wrapper}>
      {isDesktop && (
        <Popover placement="bottom" isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>{Input}</PopoverTrigger>
          <PopoverContent border={0} width={286}>
            <PopoverBody aria-label="traveler picker" p={0} m={0}>
              {Picker}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}

      {!isDesktop && (
        <>
          {Input}
          <MobileModal
            id="travellerModal"
            heading="Travellers"
            isModalVisible={isOpen && !isDesktop}
            handleModalClose={onClose}
          >
            {isOpen && Picker}
          </MobileModal>
        </>
      )}
    </div>
  )
}

export default FlightTravellerInput
