import { z } from 'zod'

export const AirportLocationSchema = z.object({
  airportCode: z.string(),
  airportName: z.string(),
  cityName: z.string(),
  countryCode: z.string(),
  countryName: z.string(),
  stateCode: z.string().optional(),
  stateName: z.string().optional(),
})

export type AirportLocation = z.infer<typeof AirportLocationSchema>

export const AirportLocationsSchema = z.array(AirportLocationSchema)
export type AirportLocations = z.infer<typeof AirportLocationsSchema>
