import React, { useMemo } from 'react'
import { Sizes } from '@reward-platform/lift/theme/sizes.types'
import QuantityPicker from '@reward-platform/lift/components/QuantityPicker'
import { useMultiStyleConfig, chakra, Box } from '@chakra-ui/react'
import { useIntl } from 'react-intl'
import TravelerTypeLabel from './TravelerTypeLabel'

export enum TravelerType {
  senior = 'senior',
  adult = 'adult',
  youth = 'youth',
  child = 'child',
  infant = 'infant',
  traveler = 'traveler',
}

export type TravelerTypeKey = keyof typeof TravelerType

export interface MinMaxQuantity {
  minCount?: number
  maxCount?: number
}

export interface TravelerData {
  quantity?: number
  limits?: MinMaxQuantity
  isVisible?: boolean
  label?: JSX.Element
  ageRange?: [number, number]
}

export type TravelersQuantity = {
  [key in TravelerTypeKey]: Required<Pick<TravelerData, 'quantity'>>
}

export type Travelers = {
  [key in TravelerTypeKey]?: TravelerData
}

const countTotalNumberOfTravelers = (travelers: Travelers | undefined) => {
  if (travelers == null) {
    return 0
  }

  return Object.values(TravelerType)
    .map((travelerType) => travelers[travelerType])
    .filter((travelerData) => Boolean(travelerData?.isVisible !== false))
    .reduce((sum, travelerData) => sum + (travelerData?.quantity ?? 0), 0)
}

export interface TravelerQuantityPickerProps {
  travelers?: Travelers
  maxTravelers: number
  spacing?: keyof Sizes
  maxTravelersPerRoom?: number
  totalTravelerCount?: number
  variant?: string
  onChange: (travelers: TravelersQuantity) => void
}

const TravelerQuantityPicker = ({
  travelers = {},
  maxTravelers,
  maxTravelersPerRoom,
  totalTravelerCount,
  spacing,
  variant,
  onChange,
}: TravelerQuantityPickerProps): JSX.Element => {
  const intl = useIntl()

  const t: Record<string, string> = useMemo(
    () => ({
      maxInfants: intl.formatMessage({
        id: 'max-infants',
        defaultMessage: `The no. of adults must be more than or equal to the number of infants (aged 0-2 years).`,
      }),
    }),
    [intl]
  )

  const totalTravelers = countTotalNumberOfTravelers(travelers)

  const travelerQuantities: TravelersQuantity = Object.entries(travelers)
    .filter(([_travelerType, travelerData]) => Boolean(travelerData?.isVisible !== false))
    .reduce(
      (acc, [travelerType, travelerData]) => {
        const quantity = travelerData.quantity ?? 0
        const min = travelerData.limits?.minCount ?? 0
        const max = travelerData.limits?.maxCount ?? Number.MAX_SAFE_INTEGER

        // Hotel only
        if (
          maxTravelersPerRoom &&
          totalTravelerCount &&
          (quantity < min || quantity > maxTravelersPerRoom || totalTravelerCount > maxTravelers)
        ) {
          throw new Error(
            `Invalid number of travelers. ${maxTravelersPerRoom} travelers max per room, ${maxTravelers} max per booking. ${totalTravelerCount} > max ${maxTravelers}`
          )
        }

        // Other ancillaries
        if (
          (!maxTravelersPerRoom && (quantity < min || quantity > max)) ||
          totalTravelers > maxTravelers
        ) {
          throw new Error(`Invalid number of travelers, ${totalTravelers} > max ${max}`)
        }

        return {
          ...acc,
          [travelerType]: { quantity: travelerData.quantity },
        }
      },
      {
        adult: { quantity: 0 },
        child: { quantity: 0 },
        youth: { quantity: 0 },
        infant: { quantity: 0 },
        senior: { quantity: 0 },
        traveler: { quantity: 0 },
      }
    )

  const entries: [TravelerType, TravelerData][] = Object.values(TravelerType).map(
    (travelerType) => [travelerType, travelers[travelerType] ?? {}]
  )

  const styles = useMultiStyleConfig('TravelerQuantityPicker', { variant })

  return (
    <chakra.ul __css={styles.list} rowGap={spacing || '4'}>
      {entries
        .filter(
          ([_, travelerData]) =>
            travelerData.isVisible !== false && travelerData.isVisible !== undefined
        )
        .map(([travelerType, { quantity, limits, label, ageRange }], index) => {
          const value = quantity ?? limits?.minCount ?? 0
          const min = limits?.minCount ?? 0

          const max = Math.min(
            limits?.maxCount ?? Number.MAX_SAFE_INTEGER,
            value + (maxTravelersPerRoom ?? maxTravelers) - totalTravelers
          )
          const Label = label
            ? () => label
            : () => (
                <TravelerTypeLabel
                  type={travelerType}
                  minMaxQuantity={limits}
                  ageRange={ageRange as [number, number]}
                />
              )

          return (
            <React.Fragment key={travelerType}>
              <chakra.li
                __css={styles.listItem}
                data-testid={`traveler-quantity-picker-${travelerType}`}
                key={travelerType}
              >
                <Label />
                <QuantityPicker
                  label={travelerType}
                  defaultValue={value}
                  min={min}
                  max={max}
                  variant={variant}
                  onSelect={(updatedQuantity) =>
                    onChange({
                      ...travelerQuantities,
                      [travelerType]: { quantity: updatedQuantity },
                    })
                  }
                />
              </chakra.li>
              {index === 0 && variant === 'error' && (
                <Box __css={styles.errorMessageBox}>{t.maxInfants}</Box>
              )}
            </React.Fragment>
          )
        })}
    </chakra.ul>
  )
}

export default TravelerQuantityPicker
