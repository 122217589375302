import React from 'react'
import { Flex, useMultiStyleConfig, chakra } from '@chakra-ui/react'
import useIntl from '~/hooks/useIntl/useIntl'
import { MinMaxQuantity, TravelerType } from './TravelerQuantityPicker'

interface LabelProps {
  type: TravelerType
  minMaxQuantity?: MinMaxQuantity
  ageRange: [number, number]
}

const TravelerTypeLabel = ({
  type,
  minMaxQuantity: { minCount = 0, maxCount = 0 } = {},
  ageRange,
}: LabelProps): JSX.Element => {
  const intl = useIntl()

  if (minCount < 0 || maxCount < 0) {
    throw new Error('Minimum and maximum quantities cannot be less than zero')
  }

  if (minCount > maxCount) {
    throw new Error('Minimum quantity cannot be greater than maximum quantity')
  }

  const t = {
    [TravelerType.senior]: intl.formatMessage({ id: 'senior', defaultMessage: 'Senior' }),
    [TravelerType.adult]: intl.formatMessage({ id: 'adult', defaultMessage: 'Adult' }),
    [TravelerType.youth]: intl.formatMessage({ id: 'youth', defaultMessage: 'Youth' }),
    [TravelerType.child]: intl.formatMessage({ id: 'child', defaultMessage: 'Child' }),
    [TravelerType.infant]: intl.formatMessage({ id: 'infant', defaultMessage: 'Infant' }),
    [TravelerType.traveler]: intl.formatMessage({ id: 'traveller', defaultMessage: 'Traveller' }),
    minQuantity: (quantity: number) =>
      intl.formatMessage({ id: 'min-quantity', defaultMessage: 'Min: {quantity}' }, { quantity }),
    maxQuantity: (quantity: number) =>
      intl.formatMessage({ id: 'max-quantity', defaultMessage: 'Max: {quantity}' }, { quantity }),
    aged: (range: string) =>
      intl.formatMessage({ id: 'aged-range', defaultMessage: 'Aged {range}' }, { range }),
  }

  const aged = {
    [TravelerType.traveler]: t.aged('No age restrictions'),
    [type]: ageRange && t.aged(ageRange.join('-')),
  }

  const styles = useMultiStyleConfig('TravelerQuantityPicker')

  return (
    <Flex __css={styles.label}>
      <chakra.p __css={styles.traveler}>
        <chakra.span __css={styles.travelerType}>{t[type]}</chakra.span> ({aged[type]})
      </chakra.p>
      {(minCount > 0 || maxCount > 0) && (
        <chakra.p __css={styles.minMax}>
          {minCount > 0 && t.minQuantity(minCount)} {maxCount > 0 && t.maxQuantity(maxCount)}
        </chakra.p>
      )}
    </Flex>
  )
}

export default TravelerTypeLabel
