import { z } from 'zod'
import { CabinClass, CabinCodeEnum, FlightSchema, FlightDateSchema, RbdEnum } from './common'

export const CabinClassResultSchema = z.object({
  price: z.number(),
  seatsAvailable: z.number(),
  cabinCode: CabinCodeEnum,
  rbd: RbdEnum,
})

export type CabinClassResult = z.infer<typeof CabinClassResultSchema>

export const FlightResultSchema = FlightSchema.extend({
  duration: z.number(),
  stops: z.number(),
  peak: z.boolean(),
  cabinClasses: z.object({
    Economy: CabinClassResultSchema.optional(),
    PremiumEconomy: CabinClassResultSchema.optional(),
    Business: CabinClassResultSchema.optional(),
    First: CabinClassResultSchema.optional(),
  }),
})

export type FlightResult = z.infer<typeof FlightResultSchema>

export type CabinName = keyof FlightResult['cabinClasses']

export const cabinNameToCabinClass: Record<CabinName, CabinClass> = {
  Economy: CabinClass.Economy,
  PremiumEconomy: CabinClass.PremiumEconomy,
  Business: CabinClass.Business,
  First: CabinClass.First,
}

export const cabinClassToCabinName: Record<CabinClass, CabinName> = {
  [CabinClass.Economy]: 'Economy',
  [CabinClass.PremiumEconomy]: 'PremiumEconomy',
  [CabinClass.Business]: 'Business',
  [CabinClass.First]: 'First',
}

export const FlightAvailabilityResponseSchema = z.object({
  inbound: z.array(FlightResultSchema),
  outbound: z.array(FlightResultSchema),
})

export const singleDigitRegexp = /^\d$/

export type FlightAvailabilityResponse = z.infer<typeof FlightAvailabilityResponseSchema>

export const FlightAvailabilityQuerySchema = z.object({
  adult: z.string().regex(singleDigitRegexp).optional(),
  child: z.string().regex(singleDigitRegexp).optional(),
  childrenAges: z.string().optional(),
  minHoursBeforeDeparture: z.number().optional(),
  departureDate: FlightDateSchema,
  fromAirportCode: z.string().length(3),
  infant: z.string().regex(singleDigitRegexp).optional(),
  returnDate: FlightDateSchema.optional(),
  toAirportCode: z.string().length(3),
  youth: z.string().regex(singleDigitRegexp).optional(),
})
export type FlightSearchQueryParameters = z.infer<typeof FlightAvailabilityQuerySchema>

export const FlightCabinSelectionSchema = FlightSchema.extend({
  cabinCode: CabinCodeEnum,
  rbd: RbdEnum,
  price: z.number(),
})

export type FlightCabinSelection = z.infer<typeof FlightCabinSelectionSchema>
