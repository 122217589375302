import { useCallback, useEffect, useState, useMemo } from 'react'
import { Box, Button, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { Checkbox, RangeDatePicker, RangeDatePickerValues } from '@reward-platform/lift/components'
import { Controller, FieldError, useForm } from 'react-hook-form'
import {
  AirportLocation,
  FlightResultsQueryDataSchema,
  FlightSearchFormData,
  FlightSearchQueryData,
  FlightSearchFormSchema,
  formDataToQueryParams,
  FlightSearchFormPaxType,
  TravellersFormData,
} from '@reward-platform/flights-schemas'
import { useRouter } from 'next/router'
import { stringify } from 'qs'
import { LocationOptionProps } from '@reward-platform/lift/components/AutoComplete/components/AutoCompleteLocationOption'
import { zodResolver } from '@hookform/resolvers/zod'
import { formatValidationMessage, getValidationErrorMessage } from '@reward-platform/utils'
import { ZodIssueCode } from 'zod'
import useIntl from '~/hooks/useIntl/useIntl'
import useGetAirport from '~/hooks/useGetAirport/useGetAirport'
import { getAirportLocations, isValidRoute } from '~/services/locationService'
import FlightTravellerInput, {
  defaultTravellerQuantity,
  MAX_TRAVELLERS,
} from '~/components/search-and-results/FlightTravellerInput/FlightTravellerInput'
import { TravelerType } from '~/components/shared/TravelerQuantityPicker/TravelerQuantityPicker'
import { ONE_DAY } from '~/utils/datetime'
import { useUser } from '~/context/user'
import formatAirportName from '~/utils/formatAirportName'
import { usePartner } from '~/context/partner'
import { parseISO } from 'date-fns'
import { SearchBox } from './SearchBox'
import { SearchFieldSwapButton } from '../SearchFieldSwapButton'
import { TravellersData } from './SearchFlightForm.types'

interface SearchFlightFormProps {
  onValidSubmit?: (data: Readonly<FlightSearchFormData>) => void
  submitText: string
}

function SearchFlightForm({ onValidSubmit, submitText }: SearchFlightFormProps): JSX.Element {
  const defaultTravellerInput: TravellersFormData = {
    ...defaultTravellerQuantity,
    childrenAges: [],
  }

  const intl = useIntl()
  const styles = useMultiStyleConfig('SearchFlightForm', {})
  const { userLocale } = useUser()
  const router = useRouter()
  const {
    setValue,
    getValues,
    control,
    watch,
    resetField,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FlightSearchFormData>({
    resolver: zodResolver(FlightSearchFormSchema),
    defaultValues: {
      oneWayOnly: false,
    },
  })

  const hasErrors = Object.keys(errors).length > 0

  const oneWay = watch('oneWayOnly')

  const validateEndDate = (values: Pick<FlightSearchFormData, 'dates'>): boolean => {
    const { endDateTime } = values.dates

    if (!oneWay && !endDateTime) {
      setError('dates', { type: 'endDateTime' })
      return false
    }

    return true
  }

  const validatePassengers = (value: TravellersData): boolean => {
    const numberOfInfants = value.childrenAges.filter((childrenAge) => childrenAge.age <= 2).length
    const adults = value.adult.quantity

    return numberOfInfants <= adults
  }

  const [fromAirportCodeState, setFromAirportCodeState] = useState<string | undefined>()
  const [toAirportCodeState, setToAirportCodeState] = useState<string | undefined>()

  const fromAirportData = useGetAirport(fromAirportCodeState)
  const toAirportData = useGetAirport(toAirportCodeState)

  const setTravellersFromURL = useCallback(
    (queryData: FlightSearchQueryData) => {
      type TravellerQuantityType = {
        [key in FlightSearchFormPaxType]?: { quantity: number }
      }

      const dynamicTravellersFromURL: TravellerQuantityType = Object.keys(defaultTravellerQuantity)
        .filter((key) => key in queryData)
        .reduce<TravellerQuantityType>((acc, type) => {
          const key = type as FlightSearchFormPaxType
          if (queryData[key as keyof FlightSearchQueryData]) {
            acc[key] = { quantity: Number(queryData[key as keyof FlightSearchQueryData]) }
          }
          return acc
        }, {})

      const travellersFromURL = {
        ...defaultTravellerQuantity,
        ...dynamicTravellersFromURL,
      }

      const totalTravelers = Object.values(travellersFromURL).reduce((acc, curr) => {
        if ('quantity' in curr) {
          return acc + curr.quantity
        }
        return acc
      }, 0)

      if (totalTravelers <= MAX_TRAVELLERS) {
        Object.values(TravelerType).forEach((type) => {
          const key = type as FlightSearchFormPaxType
          if (queryData[key as keyof FlightSearchQueryData]) {
            setValue(`travellers.${key}`, {
              quantity: Number(queryData[key as keyof FlightSearchQueryData]),
            })
          }
        })

        const ages = queryData.childrenAges ? queryData.childrenAges.split(',') : []

        setValue(
          'travellers.childrenAges',
          ages.map((age, i) => ({ age: Number(age), child: `child${i}` })) ?? []
        )
      }
    },
    [setValue]
  )

  const setInitialValuesFromQueryData = useCallback(
    (queryData: FlightSearchQueryData) => {
      if (queryData.departureDate) {
        const dateValues: { startDateTime: Date; endDateTime?: Date } = {
          startDateTime: parseISO(queryData.departureDate),
        }
        if (queryData.returnDate) {
          dateValues.endDateTime = parseISO(queryData.returnDate)
        }

        setValue('dates', dateValues)
      }

      setFromAirportCodeState(queryData.fromAirportCode)
      if (fromAirportData) {
        setValue('fromLocation', fromAirportData)
      }

      setToAirportCodeState(queryData.toAirportCode)
      if (toAirportData) {
        setValue('toLocation', toAirportData)
      }
      setValue('oneWayOnly', queryData.oneWayOnly === 'true')

      setTravellersFromURL(queryData)
    },
    [setValue, fromAirportData, toAirportData, setTravellersFromURL]
  )

  useEffect(() => {
    const result = FlightResultsQueryDataSchema.safeParse(router.query as unknown)
    if (result.success) {
      setInitialValuesFromQueryData(result.data)
    }
  }, [setInitialValuesFromQueryData, router.query])

  const onSubmit = async (data: FlightSearchFormData) => {
    if (validateEndDate(data) && validatePassengers(data.travellers)) {
      onValidSubmit?.(data)
      router.push({
        pathname: '/flights/results',
        query: stringify(formDataToQueryParams(data)),
      })
    }

    if (!validatePassengers(data.travellers)) {
      setError('travellers', { type: 'travellers' })
    }
  }

  const fromLocation = watch('fromLocation')
  const toLocation = watch('toLocation')

  // EI does not provide availability >330 days into the future
  // Customers cannot book flights within 24 hours

  const minDate = new Date(Date.now() + ONE_DAY)
  const maxDate = new Date(Date.now() + ONE_DAY * 330)

  const createAutoCompleteItem = (location: AirportLocation): LocationOptionProps => {
    const { countryName, stateName } = location
    const airportTitle = formatAirportName(location)
    return {
      value: location.airportCode,
      icon: 'flights-tab',
      label: airportTitle,
      title: airportTitle,
      subtitle: stateName ? `${stateName}, ${countryName}` : countryName,
    }
  }

  const swapLocations = () => {
    setValue('fromLocation', toLocation)
    setValue('toLocation', fromLocation)
    clearErrors(['fromLocation', 'toLocation'])
  }

  const emptyItem: LocationOptionProps = useMemo(
    () => ({
      value: '',
      icon: 'flights-tab',
      label: '',
      title: '',
      subtitle: '',
    }),
    []
  )

  const t: Record<string, string> = useMemo(
    () => ({
      from: intl.formatMessage({ id: 'fromLocation', defaultMessage: 'From' }),
      to: intl.formatMessage({ id: 'toLocation', defaultMessage: 'To' }),
      departureAirport: intl.formatMessage({
        id: 'departureAirport',
        defaultMessage: 'Departure airport',
      }),
      arrivalAirport: intl.formatMessage({
        id: 'arrivalAirport',
        defaultMessage: 'Arrival airport',
      }),
      departureDate: intl.formatMessage({ id: 'departure-date', defaultMessage: 'Departure date' }),
      returnDate: intl.formatMessage({ id: 'return-date', defaultMessage: 'Return date' }),
      searchFlight: intl.formatMessage({ id: 'search-flights', defaultMessage: 'Search Flights' }),
      oneWayOnly: intl.formatMessage({ id: 'one-way-only', defaultMessage: 'One-way only' }),
    }),
    [intl]
  )

  const { mustBeSelected } = formatValidationMessage(intl)

  const errorMap = useMemo(
    () => ({
      fromLocation: { [ZodIssueCode.invalid_type]: mustBeSelected(t.departureAirport) },
      toLocation: { [ZodIssueCode.invalid_type]: mustBeSelected(t.arrivalAirport) },
      dates: {
        endDateTime: mustBeSelected(t.returnDate),
        [ZodIssueCode.invalid_type]: mustBeSelected(t.departureDate),
      },
      startDateTime: {
        [ZodIssueCode.invalid_type]: mustBeSelected(t.departureDate),
      },
      endDateTime: {
        [ZodIssueCode.invalid_type]: mustBeSelected(t.returnDate),
      },
    }),
    [mustBeSelected, t.arrivalAirport, t.departureAirport, t.departureDate, t.returnDate]
  )

  const getFieldError = useCallback(
    (field: keyof Partial<FlightSearchFormData>) => {
      return (
        errors?.[field] &&
        getValidationErrorMessage({
          errorMap,
          errors,
          field,
          intl,
        })
      )
    },
    [errorMap, errors, intl]
  )

  const getRangeDateFieldError = (field: keyof RangeDatePickerValues) => {
    // handle the top level dates error
    if (
      errors?.dates?.type === field ||
      (field === 'startDateTime' && errors?.dates?.type === ZodIssueCode.invalid_type)
    ) {
      return getFieldError('dates')
    }

    // force required error for return date since it's optional in zod
    if (!oneWay && field === 'endDateTime' && errors?.dates?.type === ZodIssueCode.invalid_type) {
      return errorMap.endDateTime[ZodIssueCode.invalid_type]
    }

    // handle the nested dates error
    const datesErrors = errors.dates as Record<keyof RangeDatePickerValues, FieldError>
    return (
      datesErrors?.[field] &&
      getValidationErrorMessage({
        errorMap,
        errors: datesErrors,
        field,
        intl,
      })
    )
  }

  const { code: partnerId } = usePartner()

  return (
    <Box as="form" __css={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <chakra.div __css={styles.route}>
        <chakra.section __css={styles.airports}>
          <Controller
            control={control}
            name="fromLocation"
            render={({ field: { name, onChange, value } }) => (
              <SearchBox
                error={getFieldError(name)}
                selectedItem={(value && createAutoCompleteItem(value)) || emptyItem}
                onFieldCleared={() => {
                  resetField(name)
                }}
                onChange={async (location) => {
                  onChange(location)

                  if (toLocation) {
                    const routeExists = await isValidRoute(
                      location.airportCode,
                      partnerId,
                      toLocation.airportCode
                    )

                    if (!routeExists) {
                      resetField('toLocation')
                    }
                  }
                }}
                name={name}
                label={t.from}
                value={(value && createAutoCompleteItem(value).title) || ''}
                searchFunction={(partner, searchString) =>
                  getAirportLocations(searchString, partnerId)
                }
                getKey={(location) => location.airportCode}
                createAutoCompleteItem={createAutoCompleteItem}
              />
            )}
          />
          <SearchFieldSwapButton
            onClick={swapLocations}
            label="Swap flight departure and arrival locations"
          />
          <Controller
            control={control}
            name="toLocation"
            render={({ field: { name, onChange, value } }) => (
              <SearchBox
                error={getFieldError(name)}
                selectedItem={(value && createAutoCompleteItem(value)) || emptyItem}
                onFieldCleared={() => {
                  resetField(name)
                }}
                onChange={onChange}
                name={name}
                label={t.to}
                value={(value && createAutoCompleteItem(value).title) || ''}
                searchFunction={(partner, searchString) =>
                  getAirportLocations(searchString, partnerId, fromLocation?.airportCode)
                }
                getKey={(location) => location.airportCode}
                createAutoCompleteItem={createAutoCompleteItem}
                extraQueryKeys={fromLocation?.airportCode ? [fromLocation.airportCode] : undefined}
              />
            )}
          />
        </chakra.section>
        <chakra.section __css={styles.oneWay}>
          <Controller
            control={control}
            name="oneWayOnly"
            render={({ field: { onChange, value } }) => (
              <Checkbox
                tabIndex={0}
                id="oneWayOnly"
                name="oneWayOnly"
                value="true"
                isChecked={value}
                onChange={() => {
                  const dates = getValues('dates')
                  setValue('dates', { startDateTime: dates?.startDateTime, endDateTime: undefined })
                  clearErrors('dates')
                  onChange(!value)
                }}
              >
                {t.oneWayOnly}
              </Checkbox>
            )}
          />
        </chakra.section>
      </chakra.div>
      <Box gridArea="dates" __css={styles.datePickers}>
        <Controller
          control={control}
          name="dates"
          render={({ field: { onChange, value } }) => (
            <RangeDatePicker
              startLabel={t.departureDate}
              endLabel={t.returnDate}
              values={value}
              onChange={onChange}
              singleDay={oneWay}
              minDate={minDate}
              maxDate={maxDate}
              locale={userLocale}
              allowSameDay
              errors={{ getFieldError: getRangeDateFieldError }}
            />
          )}
        />
      </Box>
      <chakra.div __css={styles.equallySpacedDiv}>
        <Box gridArea="travelers" position="relative">
          <Controller
            control={control}
            name="travellers"
            defaultValue={defaultTravellerInput}
            render={({ field: { onChange, value } }) => (
              <FlightTravellerInput
                onChange={onChange}
                value={value}
                variant={validatePassengers(value) ? '' : 'error'}
              />
            )}
          />
        </Box>
        <Box gridArea="search">
          <Button type="submit" isDisabled={hasErrors} sx={styles.submitButton}>
            {submitText}
          </Button>
        </Box>
      </chakra.div>
    </Box>
  )
}

export default SearchFlightForm
