import { useIntl } from 'react-intl'

interface useFlightTravellersProps {
  adult: number
  child: number
}
export const useFormatFlightTravellers = ({ adult, child }: useFlightTravellersProps) => {
  const intl = useIntl()
  const t = {
    adults: intl.formatMessage(
      {
        id: 'adults-count',
        defaultMessage: `{count} {count, plural, one {adult} other {adults}}`,
      },
      { count: adult }
    ),
    children: intl.formatMessage(
      {
        id: 'children-count',
        defaultMessage: `{count} {count, plural, one {child} other {children}}`,
      },
      { count: child }
    ),
  }

  let output = t.adults
  if (child > 0) {
    output += `, ${t.children}`
  }

  return output
}
