import { z } from 'zod'
import { DeepPartial } from 'react-hook-form'
import { FlightAvailabilityQuerySchema } from './flight-availability'
import { AirportLocationSchema } from './location'

const TravellerQuantitySchema = z.object({
  quantity: z.number().min(0).max(9),
})

const ChildrenAgesSchema = z.array(z.object({ child: z.string(), age: z.number().min(0).max(17) }))

const TravellersSchema = z.object({
  adult: TravellerQuantitySchema,
  child: TravellerQuantitySchema,
  childrenAges: ChildrenAgesSchema,
})

const DateRangeSchema = z.object({
  startDateTime: z.date(),
  endDateTime: z.date().optional().nullable(),
})

export const FlightSearchFormSchema = z.object({
  oneWayOnly: z.boolean(),
  fromLocation: AirportLocationSchema,
  toLocation: AirportLocationSchema,
  dates: DateRangeSchema,
  travellers: TravellersSchema,
})

export type TravellersFormData = z.infer<typeof TravellersSchema>
export type FlightSearchFormData = z.infer<typeof FlightSearchFormSchema>

export type UnfilledSearchFormData = DeepPartial<FlightSearchFormData>

export const FlightResultsQueryDataSchema = FlightAvailabilityQuerySchema.extend({
  oneWayOnly: z.string().regex(/true|false/),
})

export type FlightResultsQueryData = z.infer<typeof FlightResultsQueryDataSchema>

export type FlightSearchQueryData = Partial<FlightResultsQueryData>

export type FlightSearchFormPaxType = keyof FlightSearchFormData['travellers']
