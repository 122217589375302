import React from 'react'
import { Icon, chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { IconName } from '@reward-platform/ancillaries-schemas/common'
import { IconSizes } from '@reward-platform/lift/theme/iconSize'
import { convertIconSetToChakraSVG } from '~/utils/Icon.utils'

type ButtonProps = {
  label?: string
  onClick?(): void
  disabled?: boolean
}

const SearchFieldSwapButton: React.FC<ButtonProps> = ({ label, onClick, disabled }) => {
  const styles = useMultiStyleConfig('SearchFieldSwapButton', {})

  return (
    <chakra.button
      cursor={disabled ? 'default' : 'pointer'}
      aria-label={label || 'swap button'}
      onClick={onClick}
      disabled={disabled}
      sx={styles.swapButton}
      type="button"
      data-testid="swap-button"
    >
      <Icon as={convertIconSetToChakraSVG(IconName.Enum.swap)} boxSize={IconSizes.xl} />
    </chakra.button>
  )
}

export default SearchFieldSwapButton
