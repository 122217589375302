import {
  ChildAge,
  Room,
} from '~/components/search-and-results/SearchHotelForm/TravelerRoomPicker/TravelerRoomPicker.types'

export const updateAgeOfChild = (
  childrenAges: ChildAge[],
  childToAdd: ChildAge,
  indexToReplace: number
): ChildAge[] => [
  ...childrenAges.slice(0, indexToReplace - 1),
  childToAdd,
  ...childrenAges.slice(indexToReplace),
]

export const updateChildrenAges = (
  childrenAges: Room['childrenAges'],
  numChildren: number
): ChildAge[] | undefined => {
  // Updates number of objects in array - setChildAge updates the value within the objects

  // If 0 children - reset
  if (!numChildren) {
    return undefined
  }

  // First child added but array does not exist, create with default value
  if (!childrenAges) {
    return [{ child: `child_1`, age: 0 }]
  }

  // Add child to array with default value
  if (childrenAges.length < numChildren) {
    return [...childrenAges, { child: `child_${numChildren}`, age: 0 }]
  }

  //  If number of children decreased, remove extra from end of array
  if (childrenAges.length > numChildren) {
    return childrenAges.slice(0, numChildren)
  }

  return childrenAges
}
