import React, { useState, useCallback } from 'react'

export function useScrim() {
  const [isScrimActive, setScrimActive] = useState(false)

  const openScrim = useCallback(() => {
    if (document.activeElement?.getAttribute('id') === 'remove-recent-search') {
      return
    }
    setScrimActive(true)
  }, [setScrimActive])

  const closeScrim = useCallback((e: React.FocusEvent<HTMLElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setScrimActive(false)
    }
  }, [])

  return {
    isScrimActive,
    setScrimActive,
    openScrim,
    closeScrim,
  }
}
