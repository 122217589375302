import { useEffect, useState } from 'react'

export const useLiftMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const setMediaMatches = () => {
      const media = window.matchMedia(query)
      setMatches(media.matches)
    }

    window.addEventListener('resize', setMediaMatches)

    setMediaMatches()

    return () => window.removeEventListener('resize', setMediaMatches)
  }, [query])

  return matches
}
