import { useQuery } from '@tanstack/react-query'
import { usePartner } from '~/context/partner'
import { useUser } from '~/context/user'
import { getAviosOnlyPromotion } from '~/services/contentService'

export const useAviosOnlyPromotion = () => {
  const user = useUser()
  const partner = usePartner()

  const { data: aviosOnlyPromotionContent, isLoading } = useQuery({
    queryKey: ['aviosOnlyPromotion'],
    queryFn: () => getAviosOnlyPromotion(partner.tag, user.userLocale ?? 'en-GB'),
  })

  return { aviosOnlyPromotionContent, isLoading }
}
