import { z } from 'zod'

const fourDigitRegexp = /^\d{1,4}$/

// flight dates should not have timezone information as it's always the local airport time
const flightSearchDateRegexp = /^([12]\d{3})-(0[1-9]|1[0-2])-([0-2]\d|3[01])$/
export const FlightDateSchema = z.string().regex(flightSearchDateRegexp)

export const CabinCodeEnum = z.enum(['F', 'C', 'J', 'W', 'M', 'Y'])
export type CabinCode = z.infer<typeof CabinCodeEnum>

export const RbdEnum = z.enum(['Z', 'U', 'P', 'X'])
export type Rbd = z.infer<typeof RbdEnum>

export enum CabinClass {
  Economy = 'ECONOMY',
  PremiumEconomy = 'PREMIUM_ECONOMY',
  Business = 'BUSINESS',
  First = 'FIRST',
}

export const cabinCodeToCabinClassMap: Record<CabinCode, CabinClass> = {
  [CabinCodeEnum.Enum.F]: CabinClass.First,
  [CabinCodeEnum.Enum.C]: CabinClass.Business,
  [CabinCodeEnum.Enum.J]: CabinClass.Business,
  [CabinCodeEnum.Enum.W]: CabinClass.PremiumEconomy,
  [CabinCodeEnum.Enum.M]: CabinClass.Economy,
  [CabinCodeEnum.Enum.Y]: CabinClass.Economy,
}

export const FlightsPartnerIdentifierSchema = z.enum(['EI'])
export type FlightsPartnerIdentifier = z.infer<typeof FlightsPartnerIdentifierSchema>

export const CarrierSchema = z.enum(['EI', 'EA', 'E1'])
export type Carrier = z.infer<typeof CarrierSchema>

export const FlightSchema = z.object({
  operatingCarrier: z.object({
    carrier: CarrierSchema,
    flightNumber: z.string(),
  }),
  marketingCarrier: z.object({
    carrier: CarrierSchema,
    flightNumber: z.string(),
  }),
  departureTime: z.string(),
  departureLocation: z.string(),
  arrivalTime: z.string(),
  arrivalLocation: z.string(),
  equipmentCode: z.string(),
})

export type Flight = z.infer<typeof FlightSchema>

export const isSameFlights = (flightOne: Flight, flightTwo: Flight): boolean => {
  return (
    flightOne.operatingCarrier.carrier === flightTwo.operatingCarrier.carrier &&
    flightOne.operatingCarrier.flightNumber === flightTwo.operatingCarrier.flightNumber
  )
}

export enum PassengerType {
  adult = 'adult',
  youth = 'youth',
  child = 'child',
  infant = 'infant',
}

export const PassengersSchema = z.object({
  [PassengerType.adult]: z.number(),
  [PassengerType.youth]: z.number(),
  [PassengerType.child]: z.number(),
  [PassengerType.infant]: z.number(),
})

export type Passengers = z.infer<typeof PassengersSchema>

// Information needed to uniquely identify a flight for the purpose of booking
export const FlightIdentifiersSchema = z.object({
  flightNumber: z.string().regex(fourDigitRegexp),
  marketingCarrier: CarrierSchema,
  departureDate: FlightDateSchema,
})

export const FlightLegIdentifiersSchema = FlightIdentifiersSchema.extend({
  rbdCode: RbdEnum, // same as 'bookingClass'
})

export type FlightLegIdentifiers = z.infer<typeof FlightLegIdentifiersSchema>
