import React from 'react'
import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react'

type ScrimOverlayProps = BoxProps & {
  active?: boolean
  layer?: number
}

const ScrimOverlay = (props: ScrimOverlayProps) => {
  const { active, layer, ...other } = props
  const styles = useStyleConfig('Scrim')

  return (
    <Box
      __css={styles}
      zIndex={layer ?? -1}
      opacity={active ? 1 : 0}
      visibility={active ? 'visible' : 'hidden'}
      transition={`0.25s opacity ease, 0.25s visibility ${active ? 'step-start' : 'step-end'}`}
      {...other}
    />
  )
}

export default ScrimOverlay
